import React from "react";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import Layout from "../components/Layout";
import Header from "../components/Header";
import MobileMenu from "../components/MobileMenu";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import { Link } from "gatsby";

import cross from "../assets/images/cross.jpg"


const BlogPostPage = () => (
  <Layout pageTitle="Apiton | Blog Post Page">
    {/* <SEO title={post.frontmatter.title} description={post.excerpt} /> */}


    <Header btnClass="main-nav__btn-two" />
    <MobileMenu />
    <PageHeader pageHeading="TERMS OF SERVICE" breadcrumb="1" align="text-center" />

    <section className="blog-details">
      <Container>
        <Row>
          <Col lg={12} xs={12}>

<p><span lang="en-IN">This is an agreement between YOU or the COMPANY that you represent and MEGAM TECHNOLOGIES, governing your use of Accounts Desk.</span></p>
<br />
<p><strong>Acceptance Of The Terms</strong></p>
<p>You must be of legal age to enter into a binding agreement in order to accept the Terms. If you do not agree to the General Terms, do not use Accounts Desk. You can accept the Terms by checking a checkbox or clicking on a button indicating your acceptance of the terms or by actually using Accounts Desk</p>
<br />
<p><strong>Description Of Service &ndash; Accounts Desk</strong></p>
<p>You may use Accounts Desk for your business use or for internal business purpose in the organization that you represent. You may connect to Accounts Desk using any Internet browser supported by your Service provider. You are responsible for obtaining access to the Internet and the equipment necessary to use Accounts Desk.</p>
<br />
<p><strong>Modification of Terms of Service</strong></p>
<p>We may modify the Terms upon notice to you at any time by sending email to your registered email address. If we make significant changes to the Terms that affect your rights, you will be provided with at least 30 days advance notice of the changes by email to your registered email address. You may terminate your use of Accounts Desk by providing Megam Technologies notice by email within 30 days of being notified of the availability of the modified Terms if the Terms are modified in a manner that substantially affects your rights in connection with use of the Accounts Desk. Your continued use of Accounts Desk after the effective date of any change to the Terms will be deemed to be your agreement to the modified Terms.</p>
<br />
<p><strong>User Sign up Obligations</strong></p>
<p>You need to sign up for a user account by providing all required information in order to access or use Accounts Desk. We recommend that you sign up for user accounts by providing your corporate email address. You agree to: a) provide true, accurate, current and complete information about yourself/company as prompted by the sign up process; and b) maintain and promptly update the information provided during sign up to keep it true, accurate, current, and complete. If you provide any information that is untrue, inaccurate, outdated, or incomplete, or if Megam Technologies has reasonable grounds to suspect that such information is untrue, inaccurate, outdated, or incomplete, Megam Technologies may terminate your user account and refuse current or future use of any or all of the Services</p>
<br />
<p><strong>Organization Accounts and Administrators</strong></p>
<p>You are responsible for i) ensuring confidentiality of your organization account password, ii) appointing competent individuals as administrators for managing your organization account, and iii) ensuring that all activities that occur in connection with your organization account comply with this Agreement. You understand that Megam Technologies is not responsible for account administration and internal management of the Services for you.</p>
<p>You are responsible for taking necessary steps for ensuring that your organization does not lose control of the administrator accounts. You may specify a process to be followed for recovering control in the event of such loss of control of the administrator accounts by sending an email to&nbsp;<a href="legal@megamtech.com">legal@megamtech.com</a>, provided that the process is acceptable to Megam Technologies. In the absence of any specified administrator account recovery process, Megam Technologies may provide control of an administrator account to an individual providing proof satisfactory to Megam Technologies demonstrating authorization to act on behalf of the organization. You agree not to hold Megam Technologies liable for the consequences of any action taken by Megam Technologies in good faith in this regard.</p>
<br />
<p><strong>Personal Information and Privacy</strong></p>
<p>Personal information you provide to Megam Technologies through Accounts Desk is governed by&nbsp;<Link to="/privacy/" >Megam Technologies Privacy Policy</Link>. Your election to use Accounts Desk indicates your acceptance of the terms of the&nbsp;<Link to="/privacy/" >Megam Technologies Privacy Policy</Link>. You are responsible for maintaining confidentiality of your username, password and other sensitive information. You are responsible for all activities that occur in your user account and you agree to inform us immediately of any unauthorized use of your user account by email to&nbsp; <a href="mailto:accounts@accountsdesk.in">accounts@accountsdesk.in</a>. We are not responsible for any loss or damage to you or to any third party incurred as a result of any unauthorized access and/or use of your user account, or otherwise.</p>
<br />
<p><strong>Communications from Megam Technologies</strong></p>
<p>Usage of Accounts Desk may include certain communications from Megam Technologies, such as service announcements, administrative messages and newsletters. You understand that these communications shall be considered part of using the Accounts Desk. As part of our policy to provide you total privacy, we also provide you the option of opting out from receiving newsletters from us. However, you will not be able to opt-out from receiving service announcements and administrative messages.</p>
<br />
<p><strong>Complaints</strong></p>
<p>If we receive a complaint from any person against you with respect to your activities as part of use of Accounts Desk, we will forward the complaint to the registered email address of your user account. You must respond to the complainant directly within 10 days of receiving the complaint forwarded by us and copy <a href="support@accountsdesk.in">support@accountsdesk.in</a> in the communication. If you do not respond to the complainant within 10 days from the date of our email to you, we may disclose your name and contact information to the complainant for enabling the complainant to take legal action against you. You understand that your failure to respond to the forwarded complaint within the 10 days&rsquo; time limit will be construed as your consent to disclosure of your name and contact information by Megam Technologies to the complainant.</p>
<br />
<p><strong>Payments</strong></p>
<p>Accounts Desk is available under Three (3) subscription plans of various durations. Payments for subscription plans of duration of less than a year can be made only by Credit Card. Your subscription will be automatically renewed at the end of each subscription period unless you downgrade your paid subscription plan to a free plan or inform us that you do not wish to renew the subscription. At the time of automatic renewal, the subscription fee will be charged to the Credit Card last used by you. We provide you the option of changing the details if you would like the payment for the renewal to be made through a different Credit Card. If you do not wish to renew the subscription, you must inform us at least seven days prior to the renewal date. If you have not downgraded to a free plan and if you have not informed us that you do not wish to renew the subscription, you will be presumed to have authorized Megam Technologies to charge the subscription fee to the Credit Card last used by you.</p>
<p>From time to time, we may change the price of any Service or charge for use of Services that are currently available free of charge. Any increase in charges will not apply until the expiry of your then current billing cycle. You will not be charged for using any Service unless you have opted for a paid subscription plan.</p>
<br />
<p><strong>Restrictions on Use</strong></p>
<p>In addition to all other terms and conditions of this Agreement, you shall not: (1) transfer the usage of Accounts Desk or otherwise make it available to any third party; (2) provide any service based on Accounts Desk without prior written permission; (3) display, upload, modify, publish, transmit, store, update or share any information that belongs to another person and to which you do not have any right, including personal or confidential information of any person or company without obtaining consent or permission from such person or entity; (4) use Accounts Desk in any manner that could damage, disable, overburden, impair or harm any server, network, computer system, resource of Megam Technologies; (5) violate any applicable local, state or national law; (6) use Accounts Desk in any manner that threatens the unity, integrity, defence, security or sovereignty of India, friendly relations of India with other countries, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting other countries; (7) create a false identity to mislead any person as to the identity or origin of any communication; (8) use Accounts Desk for transmitting information that is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, company or agency for financial gain or to cause any injury to any person; or (9) use Accounts Desk in a manner that relates to or encourages any activity prohibited by law in India.</p>
<br />
<p><strong>Spamming and Illegal Activities</strong></p>
<p>You agree to be solely responsible for the contents of your usage through Accounts Desk. You agree not to use Accounts Desk for illegal purposes or for the transmission of material that is unlawful, defamatory, insulting, harassing, libelous, invasive of another's privacy, abusive, threatening, harmful, vulgar, pornographic, paedophilic, harmful to children, obscene, racially or ethnically objectionable, or is otherwise objectionable, offends religious sentiments, promotes racism, contains viruses or malicious code, or that which infringes or may infringe intellectual property or other rights of another. We reserve the right to terminate your access to Accounts Desk if there are reasonable grounds to believe that you have used Accounts Desk for any illegal or unauthorized activity.</p>
<br />
<p><strong>Inactive User Accounts Policy</strong></p>
<p>We reserve the right to terminate user accounts that are inactive for a continuous period of 120 days. In the event of such termination, all data associated with such user account will be deleted. We will provide you prior notice of such termination and option to back-up your data.</p>
<br />

<p><strong>Data Ownership</strong></p>
<p>We respect your right to ownership of your data. Unless specifically permitted by you, your use of Account Desk does not grant Megam Technologies the license to use, reproduce, adapt, modify, publish or distribute the data in your user account for Megam Technologies&rsquo; commercial, marketing or any similar purpose.</p>
<br />
<p><strong>Sample files and Applications</strong></p>
<p>Megam Technologies may provide sample files and applications for the purpose of demonstrating the possibility of using Account Desk effectively for specific purposes. The information contained in any such sample files and applications consists of random data. Megam Technologies makes no warranty, either express or implied, as to the accuracy, usefulness, completeness or reliability of the information or the sample files and applications.</p>
<br />
<p><strong>Disclaimer of Warranties</strong></p>
<p>You expressly understand and agree that the use of Accounts Desk is at your sole risk. Accounts Desk is provided on an as-is-and-as-available basis. Megam Technologies expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability and fitness for a particular purpose. Megam Technologies makes no warranty that Accounts Desk will be uninterrupted, timely, secure, or error free. Use of any material downloaded or obtained through the use of Accounts Desk shall be at your own discretion and risk and you will be solely responsible for any damage to your computer system, mobile telephone, wireless device or data that results from the use of the services or the download of any such material. No advice or information, whether written or oral, obtained by you from Megam Technologies, its employees or representatives shall create any warranty not expressly stated in the terms.</p>
<br />
<p><strong>Limitation of Liability</strong></p>
<p>You agree that Megam Technologies shall, in no event, be liable for any consequential, incidental, indirect, special, punitive, or other loss or damage whatsoever or for loss of business profits, business interruption, computer failure, loss of business information, or other loss arising out of or caused by your use of or inability to use Accounts Desk, even if Megam Technologies has been advised of the possibility of such damage. In no event shall Megam Technologies entire liability to you in respect of any service, whether direct or indirect, exceed the fees paid by you towards Accounts Desk.</p>
<br />
<p><strong>Indemnification</strong></p>
<p>You agree to indemnify and hold harmless Megam Technologies, its officers, directors, employees, partners, and affiliates, from and against any losses, damages, fines and expenses (including lawyer&rsquo;s fees and costs) arising out of or relating to any claims that you have used Account Desk in violation of another party's rights, in violation of any law, in violations of any provisions of the Terms, or any other claim related to your use of Accounts Desk, except where such use is authorized by Megam Technologies.</p>
<br />
<p><strong>Governing law and Jurisdiction</strong></p>
<p>Any controversy or claim arising out of or relating to the Terms shall be settled and adjudicated exclusively by the courts of Chennai in accordance with the laws of India without regard to conflict of law principles.</p>
<br />
<p><strong>Suspension and Termination</strong></p>
<p>We may suspend your user account or temporarily disable access to Accounts Desk in the event of any suspected illegal activity, extended periods of inactivity or requests by law enforcement or other government agencies. Objections to suspension or disabling of user accounts should be made to&nbsp; <a href="legal@megamtech.com">legal@megamtech.com</a>&nbsp;within thirty days of being notified about the suspension. We may terminate a suspended or disabled user account after thirty days. We will also terminate your user account on your request.</p>
<p>Termination of user account will include denial of access to Accounts Desk, deletion of information in your user account such as your email address and password and deletion of ALL data in your user account.</p>
<br />
<p><strong>End Of Terms Of Service</strong></p>
<p>If you have any questions or concerns regarding this Agreement, please contact us at&nbsp;<a href="legal@megamtech.com">legal@megamtech.com</a></p>
<br />

          </Col>

        </Row>
      </Container>
    </section>

    <Footer />
  </Layout>
);
// export const query = graphql`
//   query($slug: String!) {
//     markdownRemark(fields: { slug: { eq: $slug } }) {
//       html
//       frontmatter {
//         title
//       }
//       excerpt
//     }
//   }`

export default BlogPostPage;
